"use client"

import Image from "next/image"
import { useRouter } from "next/navigation"
import axios from "axios"
import { useSession } from "next-auth/react"
import { PiHeart, PiHeartFill } from "react-icons/pi"
import { toast } from "sonner"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

type ProductCardType = {
  product: any
  className?: string
  fetchData?: () => void
  isWishlistPage?: boolean
}

interface ProductImage {
  s3Path: string
  fileName: string
  productSKU: string
  type: string
  order: number
}

export default function ProductCard({
  product,
  className,
  fetchData,
  isWishlistPage = false,
}: ProductCardType) {
  const { data: session } = useSession()
  const router = useRouter()

  const addToWishlist = async (productId: string, isRemove: boolean) => {
    await axios
      .post(`/api/wishlist?isRemove=${isRemove}`, {
        productId,
      })
      .then((response) => {
        if (response.status === 200 && !isRemove) {
          toast.success("Added to wishlist")
        }
        if (response.status === 200 && isRemove) {
          toast.success("Removed from wishlist")
        }

        if (fetchData) fetchData()
      })
      .catch((e) => {
        toast.error(`Failed to ${isRemove ? "remove" : "add"} to wishlist`)
      })
  }

  if (!product) return null
  return (
    <Card
      className={cn(
        "w-auto max-w-[19rem] flex-col shadow-lg border-none cursor-pointer pb-2 z-10",
        className
      )}
      onClick={() => (window.location.href = `/products/${product.id}`)}
    >
      <CardHeader className="p-2 md:p-4">
        <CardTitle className="">
          <img
            className="rounded-lg object-contain w-full"
            src={`${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_URL}${
              product.productImages.find(
                (image: ProductImage) => image.order === 1
              )?.s3Path || "/assets/images/apparel_1.jpeg"
            }`}
            alt={product.name}
            width={500}
            height={300}
          />
        </CardTitle>
      </CardHeader>
      <CardContent className="px-3 md:px-6 text-[#444] font-medium pb-2">
        <div className="flex justify-between items-center">
          <p className="text-wrap font-bold text-sm sm:text-lg">
            {product.name?.split("-")[0] || "Unknown Product"} <br />
            <span className="font-light text-xs sm:text-sm ">
              {product.name?.split("-")[1] || ""}
              {product.name?.split("-")[2]
                ? " - " + product.name.split("-")[2]
                : ""}
              <span className="ml-1 text-[#a5a5a5] font-bold">
                (
                {product.fit
                  ? product.fit.charAt(0).toUpperCase() +
                    product.fit.slice(1).toLowerCase()
                  : "Unknown"}
                )
              </span>
            </span>
          </p>

          {session ? (
            <Button
              variant="outline"
              className="rounded-full cursor-pointer flex-shrink-0"
              size="icon"
              onClick={(e) => {
                e.stopPropagation()
                if (!session) {
                  toast.error("Please login to add to wishlist")
                } else if (
                  isWishlistPage ||
                  (product.wishlistItems &&
                    product.wishlistItems[0] &&
                    product.wishlistItems[0].userId === session?.user.id)
                ) {
                  addToWishlist(product.id, true)
                } else {
                  addToWishlist(product.id, false)
                }
              }}
            >
              {isWishlistPage ||
              (product.wishlistItems &&
                product.wishlistItems[0] &&
                product?.wishlistItems[0].userId === session?.user.id) ? (
                <PiHeartFill className="text-red-500" />
              ) : (
                <PiHeart />
              )}
            </Button>
          ) : null}
        </div>

        <p className="text-[10px] md:text-[15px] mt-1 font-bold">
          ₹ {product.price}
          <span className="text-[#a5a5a5] text-xs ml-3 font-light sm:text-sm">
            * Inclusive of tax
          </span>
        </p>
      </CardContent>
    </Card>
  )
}
